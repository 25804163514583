<template>
  <a-layout class="rays-tuner-simplfiy-home">
    <a-layout-sider width="208" class="left-con">
      <a-menu
        mode="inline"
        v-model:selectedKeys="simplifyHome.menuKey"
        @click="clickMenu"
        class="left-menu"
      >
        <!-- <a-menu-item key="1">
                    <svg-icon icon-class="dashboard-menu" class="menu-icon"/>
                    <span>Dashboard</span>
                </a-menu-item> -->
        <a-menu-item key="2">
          <svg-icon
            icon-class="menu-Patlist-h"
            class="menu-icon pat"
            v-if="simplifyHome.menuKey == '2'"
          />
          <svg-icon icon-class="menu-Patlist" class="menu-icon pat" v-else />
          <span>PAT管理</span>
        </a-menu-item>
        <div class="menu-split"></div>
        <a-menu-item key="3">
          <svg-icon
            icon-class="menu-Simplymission-h"
            class="menu-icon"
            v-if="simplifyHome.menuKey == '3'"
          />
          <svg-icon icon-class="menu-Simplymission" class="menu-icon" v-else />
          <span>简化任务</span>
        </a-menu-item>
        <a-menu-item key="4">
          <svg-icon
            icon-class="menu-Flitmission-h"
            class="menu-icon"
            v-if="simplifyHome.menuKey == '4'"
          />
          <svg-icon icon-class="menu-Flitmission" class="menu-icon" v-else />
          <span>筛选任务</span>
        </a-menu-item>
        <a-menu-item key="5">
          <svg-icon
            icon-class="menu-Packmission-h"
            class="menu-icon"
            v-if="simplifyHome.menuKey == '5'"
          />
          <svg-icon icon-class="menu-Packmission" class="menu-icon" v-else />
          <span>画质检测</span>
        </a-menu-item>
        <a-menu-item key="6">
          <svg-icon
            icon-class="menu-Optimizereport-h"
            class="menu-icon"
            v-if="simplifyHome.menuKey == '6'"
          />
          <svg-icon icon-class="menu-Optimizereport" class="menu-icon" v-else />
          <span>优化报告</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout-content :style="{ margin: 0, minHeight: '280px' }">
      <router-view></router-view>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { setSimplifyHomeInfo, setPat } from '@/models/tunerStorage';
import router from '@/routes';
import store from '@/vuex/store';

export default defineComponent({
  name: 'SimplifyHome',
  components: {},
  setup() {
    const simplifyHome = computed(
      () => store.state.shaderTool.simplifyHome || { menuKey: ['2'] }
    );
    const $_pat = computed(() => store.state.shaderTool.currentPat || {});
    const toPage = (key = '2') => {
      let path;
      switch (key) {
        case '2':
          setPat({});
          path = '/mainhome/simplifyhome/pat';
          break;
        case '3':
          path = '/mainhome/simplifyhome/simplifytask';
          break;
        case '4':
          path = '/mainhome/simplifyhome/screentask';
          break;
        case '5':
          path = '/mainhome/simplifyhome/imagequality';
          break;
        case '6':
          path = '/mainhome/simplifyhome/optimizereport';
          break;
        default:
          break;
      }
      router.push({
        path,
      });
    };

    if (!$_pat.value.pat_name) {
      toPage(simplifyHome.value.menuKey[0]);
    }

    const clickMenu = ({ key }) => {
      const _simp = JSON.parse(JSON.stringify(simplifyHome.value));
      _simp.menuKey = [key];
      setSimplifyHomeInfo(_simp);
      toPage(key);
    };

    return {
      simplifyHome,
      clickMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
.rays-tuner-simplfiy-home {
  width: 100%;
  height: 100%;
}
.left-con {
  border-right: 1px solid #d8d8d8;
  background-color: #fff;
  .ant-menu {
    height: 100%;
    border-right: 0px;
    background-color: #fff;
  }
  .ant-menu-item {
    width: 192px;
    margin-left: 8px;
    padding-left: 8px !important;
    border-radius: 4px;
    &:active {
      background-color: #d8d8d8;
    }
  }
  .ant-menu-item-selected {
    background-color: #d8d8d8;
    &::after {
      opacity: 0;
    }
  }
}
.menu-icon {
  width: 24px;
  height: 24px;
  vertical-align: -6px;
  margin-right: 8px;
  &.pat {
    width: 28px;
    margin-right: 5px;
    margin-left: -1px;
  }
}
.menu-split {
  width: 192px;
  height: 1px;
  margin-left: 8px;
  border: 1px solid #f0f0f0;
}
</style>
<style lang="scss">
.rays-tuner-simplfiy-home {
  .ant-menu-item {
    &:active {
      // width: 192px;
      // margin-left: 8px;
      // border-radius: 4px;
      // padding-left: 16px !important;
      background-color: rgba(45, 38, 79, 0.08);
    }
    & > span {
      margin-left: 8px;
      font-size: 14px;
      color: #8c8c8c;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
    }
    &:hover > span {
      color: #3c3c3c;
      font-weight: 600;
    }
  }
  .ant-menu .ant-menu-item-selected {
    // width: 192px;
    // margin-left: 8px;
    // border-radius: 4px;
    // padding-left: 16px !important;
    background-color: rgba(45, 38, 79, 0.08);
    & > span {
      color: #3c3c3c;
      font-weight: 600;
    }
    &::after {
      opacity: 0;
    }
  }
}
</style>
